.App {
  font-family: sans-serif;
  text-align: center;
}

img {
  height: 100px;
}

h1 {
  color: #59363F;
}

.pagination {
  display: flex;
  flex: 1;
  justify-content: space-between;
  list-style: none;
  cursor: pointer;
  margin-top: 30px;
  margin-left: -20px;
  margin-right: 20px;
  padding-bottom: 20px;
}

.pagination ul {
  margin: 0;
  padding: 0;
}

.pagination a {
  padding: 10px;
  border-radius: 5px;
  border: 1.4px solid #59363F;
  color: #59363F;
}

.next a, .previous a {
  background-color: #f3d8d8;
}

.pagination__link--active a {
  color: #fff;
  background: #59363F;
}

.pagination__link--disabled a {
  color: rgb(198, 197, 202);
  border: 1px solid rgb(198, 197, 202);
}
